import cx from "classnames"
import { FastField } from "formik"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./price.module.css"

export const days = {
  "2 týdny": {
    5: 10,
    6: 12,
  },
  Měsíc: {
    5: 20,
    6: 24,
  },
  "Dva měsíce": {
    5: 40,
    6: 48,
  },
}

export const getTotalPrice = (price, program, week) =>
  Number(price * days[program][week]).toFixed(2)
export const getPricePerPortion = (totalPrice, program, week) =>
  Number(totalPrice / days[program][week]).toFixed(2)

function getDiscountPrice(price, plan) {
  const discount =
    plan && plan === "2 týdny" ? 5 : plan && plan === "2 měsíce" ? 8 : null
  console.log("discount: ", price, plan, discount)
  return (price - price * (discount / 100)).toFixed()
}

function getDiscountPricePerPortion(price, plan, program, week) {
  const discountPortion = plan && plan === "2 týdny" ? 5 : null
  return ((price - price * (discountPortion / 100)).toFixed() / 5).toFixed()
}

export const Price2 = ({
  price = 0,
  plan = null,
  program,
  week,
  delivery = 0,
  deliveryPrice: deliveryPriceValue = 0,
  totalPrice: totalPriceValue = 0,
  setFieldValue,
  handleSetDelivery,
}) => {
  const [discountPrice, setDiscountPrice] = useState(null)
  const [discountPortionPrice, setDiscountPortionPrice] = useState(null)
  const [deliveryPrice, setDeliveryPrice] = useState(deliveryPriceValue)
  const [totalPrice, setTotalPrice] = useState(
    window.localStorage.getItem("totalPrice")
  )

  const { t } = useTranslation()

  // temporary unnecessary
  // useEffect(() => {
  //   const discountPriceValue = getDiscountPrice(price, plan)
  //   setDiscountPrice(discountPriceValue)
  //   const discountPortionPriceValue = getDiscountPricePerPortion(price, plan)
  //   setDiscountPortionPrice(discountPortionPriceValue)
  // }, [price, plan])

  useEffect(() => {
    // console.log("--- deliveryPriceValue in Price", deliveryPriceValue);
    // setDeliveryPrice(deliveryPriceValue);
    if (deliveryPriceValue && deliveryPriceValue > 0) {
      // console.log("--- deliveryPriceValue > 0 && ", deliveryPriceValue);
      setDeliveryPrice(deliveryPriceValue)
      // console.log("--- setTotalPrice ", deliveryPriceValue, " + ", price);
      // setTotalPrice(deliveryPriceValue + price);
      // window.localStorage.setItem("totalPrice", deliveryPriceValue + price);
    } else {
      // console.log("--- deliveryPriceValue === null && price = ", price);
      setDeliveryPrice(null)
      // window.localStorage.setItem("totalPrice", price);
    }
  }, [deliveryPriceValue])

  useEffect(() => {
    // console.log("--- price in Price", price);
    // console.log("--- totalPriceValue in Price", totalPriceValue)
    const tp = Number(window.localStorage.getItem("totalPrice"))
    console.log("--- price get totalPrice in Price", tp, totalPriceValue)
    if (tp !== totalPriceValue) {
      console.log("tp !== totalPriceValue", totalPriceValue)
      setTotalPrice(totalPriceValue)
    } else {
      setTotalPrice(tp)
    }
  }, [deliveryPrice, totalPriceValue])

  // useEffect(() => {
  //   console.log("--- totalPrice in Price", totalPrice);
  //   // setFieldValue("totalPrice", totalPrice);
  // }, [totalPrice]);
  // useEffect(() => {
  //   console.log("==== Price in Price", price);
  //   const tp = getTotalPrice(totalPrice, price, program);
  //   window.localStorage.setItem("totalPrice", tp)
  //   setFieldValue("totalPrice", tp);
  // }, [price]);

  return (
    <>
      <div className={styles.price}>
        <div className={cx(styles.priceCol, styles.priceColLeft)}>
          <h5 className={styles.priceTitle}>
            {t("forms.pricePerDayLabelPart1")}
          </h5>
          <p className={styles.priceText}>{t("forms.pricePerDayLabelPart2")}</p>
        </div>
        <div className={cx(styles.priceCol)}>
          {/* <div className={styles.priceDiscountValue}>
            {discountPortionPrice && (<><span id="pricePortionDiscount">
              {discountPortionPrice}
            </span> Kč</>)}
          </div> */}
          <div className={styles.priceValue}>
            <span id="pricePortion">
              {totalPrice
                ? getPricePerPortion(totalPrice, program, week)
                : price
                ? price
                : "0"}
            </span>{" "}
            Kč
          </div>
          {deliveryPrice && (
            <div className={styles.delivery}>
              Doprava <b className={styles.deliveryPrice}>+50 Kč</b> za den
            </div>
          )}
        </div>
      </div>
      <div className={styles.price}>
        <div className={cx(styles.priceCol, styles.priceColLeft)}>
          <h5 className={styles.priceTitle}>{t("forms.priceLabel")}</h5>
        </div>
        <div className={cx(styles.priceCol)}>
          {/* <div className={styles.priceDiscountValue}>
            {discountPrice && (<><span id="priceDiscount">{discountPrice}</span> Kč</>)}
          </div> */}
          <div className={styles.priceValue}>
            <span id="price">
              {totalPriceValue
                ? totalPriceValue
                : getTotalPrice(price, program, week)}
            </span>{" "}
            Kč
          </div>
          {deliveryPrice && (
            <div className={styles.delivery}>
              Doprava <b className={styles.deliveryPrice}>+50 Kč</b> za den
            </div>
          )}
        </div>
      </div>
    </>
  )
}
