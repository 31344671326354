import {
  OrderCustomerGenderType,
  OrderFoodOnlineType,
  OrderNumberOfMealsType,
  OrderNumberOfPeopleType,
  OrderProgramDurationType,
  OrderProgramType,
  OrderWeekMealLengthType,
  OrderNumberOfMeals,
  OrderCustomerMenuType, OrderProgramDaysType, OrderCustomerWeekType,
} from './order-food-online-type'
import { getOrderFoodNumberOfMeals } from './getters'
import { useOrderFoodContext } from './store'
import { OrderFoodDeliveryType } from './order-food-delivery-type'
import { OrderFoodDeliveryPaymentSystemData } from './order-food-delivery-data'

export const useSetters = () => {
  const [state, setState] = useOrderFoodContext()

  return {
    setState,
    // online
    setOrderExeptions: exeptions =>
      setState(setOrderExeptions(state, exeptions)),
    setOrderFoodNumberOfPeopleOne: () =>
      setState(setOrderFoodNumberOfPeopleOne(state)),
    setOrderFoodNumberOfPeopleTwo: () =>
      setState(setOrderFoodNumberOfPeopleTwo(state)),
    setOrderFoodNumberOfPeopleThreeOrMore: () =>
      setState(setOrderFoodNumberOfPeopleThreeOrMore(state)),
    setOrderFoodNumberOfPeopleCompany: () =>
      setState(setOrderFoodNumberOfPeopleCompany(state)),
    setOrderFoodCustomerGenderMale: () =>
      setState(setOrderFoodCustomerGenderMale(state)),
    setOrderFoodCustomerGenderFemale: () =>
      setState(setOrderFoodCustomerGenderFemale(state)),
    setOrderFoodCustomerMenuMeat: () =>
      setState(setOrderFoodCustomerMenuMeat(state)),
    setOrderFoodCustomerMenuVege: () =>
      setState(setOrderFoodCustomerMenuVege(state)),
    setOrderFoodCustomerMenuBezRib: () =>
      setState(setOrderFoodCustomerMenuBezRib(state)),
    setOrderFoodProgramWeightGain: () =>
      setState(setOrderFoodProgramWeightGain(state)),
    setOrderProgram: value =>
      setState(setOrderProgram(state,value)),
    setOrderProgramType: value => setState(setOrderProgramType(state, value)),
    setOrderFoodProgramWeightLoss: () =>
      setState(setOrderFoodProgramWeightLoss(state)),
    setOrderFoodProgramRecruitment: () =>
      setState(setOrderFoodProgramRecruitment(state)),
    setOrderFoodProgramMaintenance: () =>
      setState(setOrderFoodProgramMaintenance(state)),
    setOrderFoodProgramLunchAndDinner: () =>
      setState(setOrderFoodProgramLunchAndDinner(state)),
    setOrderProgramDuration5Days: () =>
      setState(setOrderProgramDuration5Days(state)),
    setOrderProgramDurationTime: (value) =>
      setState(setOrderProgramDurationTime(state, value)),  
    setOrderProgramDuration7Days: () =>
      setState(setOrderProgramDuration7Days(state)),
    setOrderProgramDuration20Days: () =>

      setState(setOrderProgramDuration20Days(state)),
    setOrderProgramDuration30Days: () =>
      setState(setOrderProgramDuration30Days(state)),
    setOrderProgramDuration2Weeks: () =>
      setState(setOrderProgramDuration2Weeks(state)),
    setOrderProgramDuration1Month: () =>
      setState(setOrderProgramDuration1Month(state)),
    setOrderProgramDuration2Month: () =>
      setState(setOrderProgramDuration2Month(state)),
    setOrderProgramDuration3Month: () =>
      setState(setOrderProgramDuration3Month(state)),
    setOrderFoodNumberOfMealsBreakfast: () =>
      setState(setOrderFoodNumberOfMealsBreakfast(state)),
    setOrderFoodNumberOfMealsSnackFirst: () =>
      setState(setOrderFoodNumberOfMealsSnackFirst(state)),
    setOrderFoodNumberOfMealsLunch: () =>
      setState(setOrderFoodNumberOfMealsLunch(state)),
    setOrderFoodNumberOfMealsSnackSecond: () =>
      setState(setOrderFoodNumberOfMealsSnackSecond(state)),
    setOrderFoodNumberOfMealsDinner: () =>
      setState(setOrderFoodNumberOfMealsDinner(state)),
    setOrderFoodWeekMeal5DaysLength: () =>
      setState(setOrderFoodWeekMeal5DaysLength(state)),
    setOrderFoodWeekMeal6DaysLength: () =>
      setState(setOrderFoodWeekMeal6DaysLength(state)),
    setOrderFoodNumberOfMealsBreakfastToggle: () =>
      setState(setOrderFoodNumberOfMealsBreakfastToggle(state)),
    setOrderFoodNumberOfMealsSnackFirstToggle: () =>
      setState(setOrderFoodNumberOfMealsSnackFirstToggle(state)),
    setOrderFoodNumberOfMealsLunchToggle: () =>
      setState(setOrderFoodNumberOfMealsLunchToggle(state)),
    setOrderFoodNumberOfMealsSnackSecondToggle: () =>
      setState(setOrderFoodNumberOfMealsSnackSecondToggle(state)),
    setOrderFoodNumberOfMealsDinnerToggle: () =>
      setState(setOrderFoodNumberOfMealsDinnerToggle(state)),
    setOrderFoodNumberOfKcal: value =>
      setState(setOrderFoodNumberOfKcal(state, value)),
    setOrderFoodDiscount: value => setState(setOrderFoodDiscount(state, value)),
    setOrderFoodPriceDiscount: value =>
      setState(setOrderFoodPriceDiscount(state, value)),
    setOrderFoodPromoCode: value =>
      setState(setOrderFoodPromoCode(state, value)),
    setIsCompanyOrder: value => setState(setIsCompanyOrder(state, value)),
    setOrderFoodNumberOfMeals: value =>
      setState(setOrderFoodNumberOfMeals(state, value)),
    // contact-information
    //
    // delivery
    setOrderFoodDeliveryAddress: value =>
      setState(setOrderFoodDeliveryAddress(state, value)),
    setOrderFoodDeliveryCity: value =>
      setState(setOrderFoodDeliveryCity(state, value)),
    setOrderFoodDeliveryPrice: value =>
      setState(setOrderFoodDeliveryPrice(state, value)),
    setOrderFoodDeliveryCalendar: value =>
      setState(setOrderFoodDeliveryCalendar(state, value)),
    setOrderFoodDeliveryTime: value =>
      setState(setOrderFoodDeliveryTime(state, value)),
    setOrderFoodDeliveryPaymentPayPal: () =>
      setState(setOrderFoodDeliveryPaymentPayPal(state)),
    setOrderFoodDeliveryPaymentApplePay: () =>
      setState(setOrderFoodDeliveryPaymentApplePay(state)),
    setOrderFoodDeliveryPaymentCard: () =>
      setState(setOrderFoodDeliveryPaymentCard(state)),
    setOrderFoodDeliveryPaymentBank: () =>
      setState(setOrderFoodDeliveryPaymentBank(state)),

    setOrderFoodDeliveryPaymentPayPalToggle: () =>
      setState(setOrderFoodDeliveryPaymentPayPalToggle(state)),
    setOrderFoodDeliveryPaymentApplePayToggle: () =>
      setState(setOrderFoodDeliveryPaymentApplePayToggle(state)),
    setOrderFoodDeliveryPaymentCardToggle: () =>
      setState(setOrderFoodDeliveryPaymentCardToggle(state)),
    setOrderFoodDeliveryPaymentBankToggle: () =>
      setState(setOrderFoodDeliveryPaymentBankToggle(state)),
    setOrderMondayFood: () =>
      setState(setOrderMondayFood(state)),
    setOrderTuesdayFood: () =>
      setState(setOrderTuesdayFood(state)),
    setOrderWednesdayFood: () =>
      setState(setOrderWednesdayFood(state)),
    setOrderThursdayFood: () =>
      setState(setOrderThursdayFood(state)),
    setOrderFridayFood: () =>
      setState(setOrderFridayFood(state)),
    setOrderSaturdayFood: () =>
      setState(setOrderSaturdayFood(state)),
    setOrderFoodDeliveryTerms1Toggle: () =>
      setState(setOrderFoodDeliveryTerms1Toggle(state)),
    setOrderFoodDeliveryTerms2Toggle: () =>
      setState(setOrderFoodDeliveryTerms2Toggle(state)),
  }
}

// online
const setOrderFoodNumberOfPeopleOne = (state = {}) => {
  return {
    ...state,
    [OrderFoodOnlineType.NumberOfPeople]: OrderNumberOfPeopleType.One,
  }
}

const setOrderExeptions = (state, exeptions)=>{
  return {
    ...state,
    [OrderFoodOnlineType.Exeptions]: exeptions,
  }
}

const setOrderFoodProgramWeightGain = state => {
  return {
    ...state,
    [OrderFoodOnlineType.Program]: OrderProgramType.WeightGainProgram,
  }
}

const setOrderProgram= (state,value) => {
  return {
    ...state,
    [OrderFoodOnlineType.Program]: value,
  }
}

const setOrderProgramType= (state,value) => {
  return {
    ...state,
    [OrderFoodOnlineType.ProgramType]: value,
  }
}

const setOrderFoodNumberOfPeopleTwo = state => {
  return {
    ...state,
    [OrderFoodOnlineType.NumberOfPeople]: OrderNumberOfPeopleType.Two,
  }
}

const setOrderFoodNumberOfPeopleThreeOrMore = state => {
  return {
    ...state,
    [OrderFoodOnlineType.NumberOfPeople]: OrderNumberOfPeopleType.ThreeOrMore,
  }
}

const setOrderFoodNumberOfPeopleCompany = state => {
  return {
    ...state,
    [OrderFoodOnlineType.NumberOfPeople]: OrderNumberOfPeopleType.TheCompany,
  }
}

//

const setOrderFoodCustomerGenderMale = state => {
  return {
    ...state,
    [OrderFoodOnlineType.CustomerGender]: OrderCustomerGenderType.Male,
  }
}

const setOrderFoodCustomerGenderFemale = state => {
  return {
    ...state,
    [OrderFoodOnlineType.CustomerGender]: OrderCustomerGenderType.Female,
  }
}

//

const setOrderFoodCustomerMenuMeat = state => {
  return {
    ...state,
    [OrderFoodOnlineType.CustomerMenu]: OrderCustomerMenuType.Meat,
  }
}

const setOrderFoodCustomerMenuVege = state => {
  return {
    ...state,
    [OrderFoodOnlineType.CustomerMenu]: OrderCustomerMenuType.Vege,
  }
}

const setOrderFoodCustomerMenuBezRib = state => {
  return {
    ...state,
    [OrderFoodOnlineType.CustomerMenu]: OrderCustomerMenuType.BEZ_RIB,
  }
}

//

const setOrderFoodProgramWeightLoss = state => {
  return {
    ...state,
    [OrderFoodOnlineType.Program]: OrderProgramType.WeightLossProgram,
  }
}

const setOrderFoodProgramRecruitment = state => {
  return {
    ...state,
    [OrderFoodOnlineType.Program]: OrderProgramType.RecruitmentProgram,
  }
}

const setOrderFoodProgramMaintenance = state => {
  return {
    ...state,
    [OrderFoodOnlineType.Program]: OrderProgramType.MaintenanceProgram,
  }
}

const setOrderFoodProgramLunchAndDinner = state => {
  return {
    ...state,
    [OrderFoodOnlineType.Program]: OrderProgramType.LunchAndDinnerProgram,
  }
}

//

const setOrderMondayFood = state => {
  return {
    ...state,
    [OrderFoodOnlineType.CustomerMenu]: OrderCustomerWeekType.Monday,
  }
}
//


const setOrderTuesdayFood = state => {
  return {
    ...state,
    [OrderFoodOnlineType.CustomerMenu]: OrderCustomerWeekType.Tuesday,
  }
}
//

const setOrderWednesdayFood = state => {
  return {
    ...state,
    [OrderFoodOnlineType.CustomerMenu]: OrderCustomerWeekType.Wednesday,
  }
}
//

const setOrderThursdayFood = state => {
  return {
    ...state,
    [OrderFoodOnlineType.CustomerMenu]: OrderCustomerWeekType.Thursday,
  }
}
//

const setOrderFridayFood = state => {
  return {
    ...state,
    [OrderFoodOnlineType.CustomerMenu]: OrderCustomerWeekType.Friday,
  }
}
//

const setOrderSaturdayFood = state => {
  return {
    ...state,
    [OrderFoodOnlineType.CustomerMenu]: OrderCustomerWeekType.Saturday,
  }
}
//


const setOrderProgramDuration2Weeks = state => {
  return {
    ...state,
    [OrderFoodOnlineType.ProgramDuration]: OrderProgramDurationType.TwoWeeks,
  }
}
const setOrderProgramDuration5Days = state => {
  return {
    ...state,
    [OrderFoodOnlineType.ProgramDaysDuration]: OrderProgramDaysType.days5,
  }
}

const setOrderProgramDuration7Days = state => {
  return {
    ...state,
    [OrderFoodOnlineType.ProgramDaysDuration]: OrderProgramDaysType.days7,
  }
}

const setOrderProgramDuration20Days = state => {
  return {
    ...state,
    [OrderFoodOnlineType.ProgramDaysDuration]: OrderProgramDaysType.days20,
  }
}

const setOrderProgramDurationTime = (state, value)=>{
  return {
    ...state,
    [OrderFoodOnlineType.ProgramDurationTime] : value
  }
}

const setOrderProgramDuration30Days = state => {
  return {
    ...state,
    [OrderFoodOnlineType.ProgramDaysDuration]: OrderProgramDaysType.days30,
  }
}

const setOrderProgramDuration1Month = state => {
  return {
    ...state,
    [OrderFoodOnlineType.ProgramDuration]: OrderProgramDurationType.OneMonth,
  }
}

const setOrderProgramDuration2Month = state => {
  return {
    ...state,
    [OrderFoodOnlineType.ProgramDuration]: OrderProgramDurationType.TwoMonth,
  }
}

const setOrderProgramDuration3Month = state => {
  return {
    ...state,
    [OrderFoodOnlineType.ProgramDuration]: OrderProgramDurationType.ThreeMonth,
  }
}

//

const setOrderFoodNumberOfMealsBreakfast = state => {
  const meals = getOrderFoodNumberOfMeals(state)
  const data = meals.concat(OrderNumberOfMealsType.Breakfast)

  return {
    ...state,
    [OrderFoodOnlineType.NumberOfMeals]: data.filter(
      (element, index) => data.indexOf(element) === index
    ),
  }
}

const setOrderFoodNumberOfMealsBreakfastToggle = state => {
  const meals = getOrderFoodNumberOfMeals(state)
  const found = meals.find(x => x === OrderNumberOfMealsType.Breakfast)
  const data = found
    ? meals.filter(x => x !== OrderNumberOfMealsType.Breakfast)
    : meals.concat(OrderNumberOfMealsType.Breakfast)

  return {
    ...state,
    [OrderFoodOnlineType.NumberOfMeals]: data.filter(
      (element, index) => data.indexOf(element) === index
    ),
  }
}

const setOrderFoodNumberOfMealsSnackFirst = state => {
  const meals = getOrderFoodNumberOfMeals(state)
  const data = meals.concat(OrderNumberOfMealsType.SnackFirst)

  return {
    ...state,
    [OrderFoodOnlineType.NumberOfMeals]: data.filter(
      (element, index) => data.indexOf(element) === index
    ),
  }
}

const setOrderFoodNumberOfMeals = (state, value) => {
  const numberOfMeals = Object.values(OrderNumberOfMeals).find(
    val => val === value
  )
  return {
    ...state,
    [OrderFoodOnlineType.NumberOfMeals]: numberOfMeals,
  }
}

const setOrderFoodNumberOfMealsSnackFirstToggle = state => {
  const meals = getOrderFoodNumberOfMeals(state)
  const found = meals.find(x => x === OrderNumberOfMealsType.SnackFirst)
  const data = found
    ? meals.filter(x => x !== OrderNumberOfMealsType.SnackFirst)
    : meals.concat(OrderNumberOfMealsType.SnackFirst)

  return {
    ...state,
    [OrderFoodOnlineType.NumberOfMeals]: data.filter(
      (element, index) => data.indexOf(element) === index
    ),
  }
}

const setOrderFoodNumberOfMealsLunch = state => {
  const meals = getOrderFoodNumberOfMeals(state)
  const data = meals.concat(OrderNumberOfMealsType.Lunch)

  return {
    ...state,
    [OrderFoodOnlineType.NumberOfMeals]: data.filter(
      (element, index) => data.indexOf(element) === index
    ),
  }
}

const setOrderFoodNumberOfMealsLunchToggle = state => {
  const meals = getOrderFoodNumberOfMeals(state)
  const found = meals.find(x => x === OrderNumberOfMealsType.Lunch)
  const data = found
    ? meals.filter(x => x !== OrderNumberOfMealsType.Lunch)
    : meals.concat(OrderNumberOfMealsType.Lunch)

  return {
    ...state,
    [OrderFoodOnlineType.NumberOfMeals]: data.filter(
      (element, index) => data.indexOf(element) === index
    ),
  }
}

const setOrderFoodNumberOfMealsSnackSecond = state => {
  const meals = getOrderFoodNumberOfMeals(state)
  const data = meals.concat(OrderNumberOfMealsType.SnackSecond)

  return {
    ...state,
    [OrderFoodOnlineType.NumberOfMeals]: data.filter(
      (element, index) => data.indexOf(element) === index
    ),
  }
}

const setOrderFoodNumberOfMealsSnackSecondToggle = state => {
  const meals = getOrderFoodNumberOfMeals(state)
  const found = meals.find(x => x === OrderNumberOfMealsType.SnackSecond)
  const data = found
    ? meals.filter(x => x !== OrderNumberOfMealsType.SnackSecond)
    : meals.concat(OrderNumberOfMealsType.SnackSecond)

  return {
    ...state,
    [OrderFoodOnlineType.NumberOfMeals]: data.filter(
      (element, index) => data.indexOf(element) === index
    ),
  }
}

const setOrderFoodNumberOfMealsDinner = state => {
  const meals = getOrderFoodNumberOfMeals(state)
  const data = meals.concat(OrderNumberOfMealsType.Dinner)

  return {
    ...state,
    [OrderFoodOnlineType.NumberOfMeals]: data.filter(
      (element, index) => data.indexOf(element) === index
    ),
  }
}

const setOrderFoodNumberOfMealsDinnerToggle = state => {
  const meals = getOrderFoodNumberOfMeals(state)
  const found = meals.find(x => x === OrderNumberOfMealsType.Dinner)
  const data = found
    ? meals.filter(x => x !== OrderNumberOfMealsType.Dinner)
    : meals.concat(OrderNumberOfMealsType.Dinner)

  return {
    ...state,
    [OrderFoodOnlineType.NumberOfMeals]: data.filter(
      (element, index) => data.indexOf(element) === index
    ),
  }
}

//

const setOrderFoodNumberOfKcal = (state, value) => {
  return {
    ...state,
    [OrderFoodOnlineType.NumberOfKcal]: value,
  }
}

//

const setOrderFoodWeekMeal5DaysLength = state => {
  return {
    ...state,
    [OrderFoodOnlineType.WeekMealLength]: OrderWeekMealLengthType.FiveDays,
  }
}

const setOrderFoodWeekMeal6DaysLength = state => {
  return {
    ...state,
    [OrderFoodOnlineType.WeekMealLength]: OrderWeekMealLengthType.SixDays,
  }
}

//

const setOrderFoodDiscount = (state, value) => {
  return {
    ...state,
    [OrderFoodOnlineType.Discount]: value,
  }
}

const setOrderFoodPriceDiscount = (state, value) => {
  return {
    ...state,
    [OrderFoodOnlineType.PriceDiscount]: value,
  }
}

const setOrderFoodPromoCode = (state, value) => {
  return {
    ...state,
    [OrderFoodOnlineType.PromoCode]: value,
  }
}

// contact-information

const setIsCompanyOrder = (state, value) => {
  return {
    ...state,
    [OrderFoodOnlineType.IsCompanyOrder]: value,
  }
}

// delivery
const setOrderFoodDeliveryAddress = (state, value) => {
  return {
    ...state,
    [OrderFoodDeliveryType.Address]: value,
  }
}

//



const setOrderFoodDeliveryCity = (state, value) => {
  return {
    ...state,
    [OrderFoodDeliveryType.City]: value,
  }
}
const setOrderFoodDeliveryPrice = (state, value) => {
  return {
    ...state,
    [OrderFoodDeliveryType.Price]: value,
  }
}

const setOrderFoodDeliveryCalendar = (state, value) => {
  return {
    ...state,
    [OrderFoodDeliveryType.Calendar]: value,
  }
}

const setOrderFoodDeliveryTime = (state, value = []) => {
  return {
    ...state,
    [OrderFoodDeliveryType.Time]: value,
  }
}

const setOrderFoodDeliveryPaymentPayPal = state => {
  return {
    ...state,
    [OrderFoodDeliveryType.PaymentSystem]:
      OrderFoodDeliveryPaymentSystemData.PayPal,
  }
}

const setOrderFoodDeliveryPaymentApplePay = state => {
  return {
    ...state,
    [OrderFoodDeliveryType.PaymentSystem]:
      OrderFoodDeliveryPaymentSystemData.ApplePay,
  }
}

const setOrderFoodDeliveryPaymentCard = state => {
  return {
    ...state,
    [OrderFoodDeliveryType.PaymentSystem]:
      OrderFoodDeliveryPaymentSystemData.Card,
  }
}

const setOrderFoodDeliveryPaymentBank = state => {
  return {
    ...state,
    [OrderFoodDeliveryType.PaymentSystem]:
      OrderFoodDeliveryPaymentSystemData.Bank,
  }
}

const setOrderFoodDeliveryPaymentPayPalToggle = state => {
  const paymentSystem = state?.[OrderFoodDeliveryType.PaymentSystem]
  const found = paymentSystem === OrderFoodDeliveryPaymentSystemData.PayPal
  const data = !found ? OrderFoodDeliveryPaymentSystemData.PayPal : undefined

  return {
    ...state,
    [OrderFoodDeliveryType.PaymentSystem]: data,
  }
}

const setOrderFoodDeliveryPaymentApplePayToggle = state => {
  const paymentSystem = state?.[OrderFoodDeliveryType.PaymentSystem]
  const found = paymentSystem === OrderFoodDeliveryPaymentSystemData.ApplePay
  const data = !found ? OrderFoodDeliveryPaymentSystemData.ApplePay : undefined

  return {
    ...state,
    [OrderFoodDeliveryType.PaymentSystem]: data,
  }
}

const setOrderFoodDeliveryPaymentCardToggle = state => {
  const paymentSystem = state?.[OrderFoodDeliveryType.PaymentSystem]
  const found = paymentSystem === OrderFoodDeliveryPaymentSystemData.Card
  const data = !found ? OrderFoodDeliveryPaymentSystemData.Card : undefined

  return {
    ...state,
    [OrderFoodDeliveryType.PaymentSystem]: data,
  }
}

const setOrderFoodDeliveryPaymentBankToggle = state => {
  const paymentSystem = state?.[OrderFoodDeliveryType.PaymentSystem]
  const found = paymentSystem === OrderFoodDeliveryPaymentSystemData.Bank
  const data = !found ? OrderFoodDeliveryPaymentSystemData.Bank : undefined

  return {
    ...state,
    [OrderFoodDeliveryType.PaymentSystem]: data,
  }
}

const setOrderFoodDeliveryTerms1Toggle = state => {
  const found = state?.[OrderFoodDeliveryType.Terms1]
  const data = !found

  return {
    ...state,
    [OrderFoodDeliveryType.Terms1]: data,
  }
}

const setOrderFoodDeliveryTerms2Toggle = state => {
  const found = state?.[OrderFoodDeliveryType.Terms2]
  const data = !found

  return {
    ...state,
    [OrderFoodDeliveryType.Terms2]: data,
  }
}
