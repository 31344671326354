import { FastField, Field } from "formik"
import React, { forwardRef, useState } from "react"
import { debounce } from "lodash"
import { useTranslation } from "react-i18next"
import cx from "classnames"
import orderFormStyles from "../order-form2.module.css"
import { days } from "../../order-price2"

import styles from "./input.module.css"

export const PromoInput = forwardRef(
  ({ setFieldValue, setPrice, address, program, week }, ref) => {
    const [promoValue, setPromoValue] = React.useState("")
    const [promoData, setPromoData] = React.useState({})
    // const [prevPrice, setPrevPrice] = React.useState(() => Number(window.localStorage.getItem("price")) + Number((window.localStorage.getItem("deliveryPrice") || 0)));
    const [requestMessage, setMessage] = useState("")
    const [isApplyed, setIsApplyed] = useState(false)

    const { t } = useTranslation()

    async function checkPromo(promo) {
      // console.log("price, promo query value", price, promo);

      const prevPrice = Number(window.localStorage.getItem("price"))
      const totalPriceStore = Number(window.localStorage.getItem("totalPrice"))

      const programDirectly = document.querySelector('[name="promo"]').dataset
        .program

      console.log("4. === programDirectly in  Promo", programDirectly)

      // console.log("prevPrice in checkPromo", prevPrice)

      if (promo === "") {
        // just hide message cos' we don't have opportunity change code if applyed
        setMessage("")
        // setPrice(prevPrice);
        setFieldValue("discount", 0)
        // setFieldValue("totalPrice", prevPrice)
        return
      }

      let totalPrice, discount, message

      const response = await fetch(
        `${process.env.GATSBY_APP_API_URL}/check-promo`,
        {
          // const req = await fetch("https://test.nutritionpro.cz/api/check-promo", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            price:
              Number(totalPriceStore) > 0 ? Number(totalPriceStore) : prevPrice,
            promocode: promo,
            branchId: process.env.GATSBY_BRANCH_ID,
          }),
        }
      )

      // await console.log("promo res: ", JSON.stringify(response));

      if (response.ok) {
        const body = await response.json()
        totalPrice = await body?.totalPrice
        discount = await body?.discount
        message = await body?.msg

        await setMessage(message)
        await setIsApplyed(discount > 0)
        await setPrice(
          Number(totalPrice / days[programDirectly][week]).toFixed(2)
        )
        console.log(
          "2. === price in promo OK",
          programDirectly,
          Number(totalPrice / days[programDirectly][week]).toFixed(2)
        )
        await window.localStorage.setItem(
          "price",
          Number(totalPrice / days[programDirectly][week]).toFixed(2)
        )
        await window.localStorage.setItem("totalPrice", totalPrice)
        await setFieldValue("totalPrice", totalPrice)
        await window.localStorage.setItem("isDiscount", true)

        if (promo.length > 0) {
          await setFieldValue("discount", discount)
        }
      } else {
        await setIsApplyed(false)
        await setPrice(prevPrice)
        await setFieldValue("totalPrice", totalPriceStore)
        await setMessage("")
        await window.localStorage.setItem("isDiscount", false)
      }

      return { totalPrice, discount, message }
    }

    const debouncedCheckPromo = React.useRef(
      debounce(async promo => {
        setPromoData(await checkPromo(promo))
      }, 1000)
    ).current

    async function handleChange(e) {
      setPromoValue(e.target.value)
      debouncedCheckPromo(e.target.value)
    }

    React.useEffect(() => {
      return () => {
        debouncedCheckPromo.cancel()
      }
    }, [debouncedCheckPromo])

    React.useEffect(() => {
      setFieldValue("promo", promoValue)
    }, [promoValue])

    // React.useEffect(() => {
    //     let prevPriceFromBrowser = Number(window.localStorage.getItem("price"))
    //     let deliveryPriceFromBrowser = Number((window.localStorage.getItem("deliveryPrice") || 0))
    //     console.log("prevPriceFromBrowser", prevPriceFromBrowser);
    //     console.log("deliveryPriceFromBrowser", deliveryPriceFromBrowser);
    //     setPrevPrice(prevPriceFromBrowser + deliveryPriceFromBrowser);
    // })

    React.useEffect(() => {
      // console.log("-- PROMO address changed", address);
      setPromoValue("")
      setMessage("")
    }, [address])

    React.useEffect(() => {
      console.log("3. === program in Promo effect", program)
    }, [program])

    return (
      <>
        <FastField
          innerRef={ref}
          component="input"
          type="text"
          name="promo"
          className={cx(orderFormStyles.input, orderFormStyles.promoInput, {
            [styles.disabled]: isApplyed && requestMessage.length > 0,
          })}
          data-program={program}
          placeholder={t("forms.onlineOrderFormPromoCodeLabel")}
          value={promoValue}
          onChange={handleChange}
          disabled={isApplyed && requestMessage.length > 0}
        />
        {requestMessage.length > 0 && (
          <span
            className={cx(styles.inputHelper)}
            style={{ color: isApplyed ? "#3dc383" : "#FF4343" }}
          >
            {requestMessage}
          </span>
        )}
      </>
    )
  }
)
